<template>
  <div class="about-us-wrapper">
    <div class="full-with-banner">
       <Nav nums='1' :isColor=isFFF :isChange=isShow />
      <div class="homebanner">
           <Video :bannerArr='coverBanner'/>
         </div>
    </div>
    <div class="career-wrapper">
      <div class="career-background">
        <img src="../../assets/about-us/career-bg.png" alt="发展历程">
      </div>
      <div class="restrict-content career-title">
        <SectionTitle title="development" :with-highlighted-tail="false" :with-dot="false"/>
        <SectionTitle title="history"/>
        <SectionSubTitle title="发展历程"/>
      </div>
      <div class="events-wrapper">
         <swiper class="prize-swiper-wrapper events-box" :options="swiperHistory">
            <swiper-slide class="prize-image" v-for="(s, index) in historyArr" :key='index'>
               <div class="event-row">
                  <div class="event-wrapper" v-for="(item, index) in s.slice(0,5)" :key='index'>
                    <div class="name">{{item.title}}</div>
                    <div class="desc">{{item.content}}</div>
                  </div>
                </div>
                <div class="timeline" />
                <div class="event-row leg-on-top">
                  <div class="event-wrapper"  v-for="(item, index) in s.slice(5)" :key='index'>
                    <div class="name">{{item.title}}</div>
                    <div class="desc">
                      {{item.content}}
                    </div>
                  </div>
                </div>
            </swiper-slide>
          </swiper>
          <div class="move move1">
             <div class="event-row">
                  <div class="event-wrapper" v-for="(item, index) in historyArr1===[]?'':historyArr1.slice(0,historyArr1.length/2+1)" :key='index'>
                    <div class="name">{{item.title}}</div>
                    <div class="desc">{{item.content}}</div>
                  </div>
                </div>
                <div class="timeline"/>
                <div class="event-row leg-on-top">
                  <div class="event-wrapper"  v-for="(item, index) in historyArr1===[]?'':historyArr1.slice(historyArr1.length/2+1)" :key='index'>
                    <div class="name">{{item.title}}</div>
                    <div class="desc">
                      {{item.content}}
                    </div>
                  </div>
                </div>
          </div>
      </div>
    </div>
    <div class="part3">
                <div class="title">
                    <SectionTitle title="LAOKENHEALTH" :with-highlighted-tail="false" :with-dot="false"/>
                    <SectionTitle title="ADVANTAGE"/>
                    <SectionSubTitle title="老肯健康优势"/>
                </div>
                <div class="part3_body">
                   <div class="box">
                        <div class="item1"  v-for="(item, index) in prospectsArr" :key='index'>
                        <div class="title1">{{item.title}}</div>
                        <div class="info">{{item.content}}</div>
                    </div>
                   </div>
                </div>
            </div>

    <div class="restrict-content prizes-wrapper"  id="usSgs">
      <SectionTitle title="enterprise" :with-highlighted-tail="false" :with-dot="false"/>
      <SectionTitle title="certification"/>
      <SectionSubTitle title="企业认证"/>
      <div class="prize-row row-1">
        <div class="row-number">01</div>
        <div class="row-name">老肯证书</div>
        <div class="row-bg"/>
        <div class="prize-wrapper">
          <swiper class="prize-swiper-wrapper" :options="swiperOptions">
            <swiper-slide class="prize-image" v-for="image in patents" :key="image">
              <img :src="image" :alt="image">
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="prize-row row-2">
        <div class="row-number">02</div>
        <div class="row-name">老肯荣誉</div>
        <div class="row-bg"/>
        <div class="prize-wrapper">
          <swiper class="prize-swiper-wrapper" :options="swiperOptions">
            <swiper-slide class="prize-image" v-for="image in honors" :key="image">
              <img :src="image" :alt="image">
            </swiper-slide>
          </swiper>
        </div>
      </div>
      <div class="prize-row row-3">
        <div class="row-number">03</div>
        <div class="row-name">老肯专利</div>
        <div class="row-bg"/>
        <div class="prize-wrapper">
          <swiper class="prize-swiper-wrapper" :options="swiperOptions">
            <swiper-slide class="prize-image" v-for="image in prizes" :key="image">
              <img :src="image" :alt="image">
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <Floor/>
  </div>
</template>

<script>
import NavMixin from '@/mixins/nav-minxin'
import { imgHttp, request } from '@/apis/http'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'
import Video from '../../components/video.vue'
const TYPES = {
  patent: '1',
  honor: '2',
  prize: '3'
}

/**
 * @typedef prize
 * @property {string} id
 * @property {string | string[]} images
 * @property {'1' | '2' | '3'} type
 */

export default {
  components: {
    Swiper,
    SwiperSlide,
    Video
  },
  mixins: [
    NavMixin
  ],
  name: 'AboutUs',
  data () {
    return {
      /**
       * @type string[]
       */
      patents: [],
      /**
       * @type string[]
       */
      honors: [],
      /**
       * @type string[]
       */
      prizes: [],

      swiperOptions: {
        slidesPerView: 3,
        spaceBetween: 10,
        centeredSlides: true,
        loop: true,
        autoplay: true
      },
      swiperHistory:{
        slidesPerView: 1,
        spaceBetween: 10,
        centeredSlides: true,
        loop: false,
        autoplay: true
      },
        coverBanner:[],
        bannerVideo:true,
        isFFF:' #fff',
        isShow:true,
        historyArr:[],
        historyArr1:[],
        prospectsArr:[],
        bannerImg:[]
    }
  },
  beforeMount () {
    this.fetchPrizes()
    this.bigBanner()
    this.course()
    this.prospects()
    scrollTo(0, 0)
  },
  methods: {
    async fetchPrizes () {
      const res = await request({ url: '/api/index/certiflist' })
      // 处理图片
      for (const data of res.data.data || []) {
        data.images = data.images.split(',').map(image => `${imgHttp}${image}`)
        switch (data.type) {
          case TYPES.patent:
            this.patents.push(...data.images)
            break
          case TYPES.honor:
            this.honors.push(...data.images)
            break
          case TYPES.prize:
            this.prizes.push(...data.images)
            break
        }
      }

      this.patents = [...this.patents.reverse()]
      this.honors = [...this.honors.reverse()]
      this.prizes = [...this.prizes.reverse()]
    },
    async bigBanner () {
      const res = await request({
        url: '/api/index/cover?type=4'
      })
      res.data.data.map(i => {
       if (i.images !== ''){
            i.images = i.images.split(',').map(r => {
                r = imgHttp + r
                return r
            })
          }
          if (i.videos !== ''){
              i.videos = i.videos.split(',').map(r => {
              r = imgHttp + r
              return r
          })
          }
          i.arr = [...i.videos, ...i.images]
          return i
      })
      this.coverBanner = res.data.data[0].arr
      console.log(this.coverBanner)
   },
   async course () { // 发展历程
      const res = await request({
        url: '/api/index/course?type=1'
      })
      const pages = []
      res.data.data.forEach((item, index) => {
        const page = Math.floor(index / 9)
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      this.historyArr = pages
      this.historyArr1 = res.data.data
   },
   async prospects () { // 健康优势
      const res = await request({
        url: '/api/index/course?type=4'
      })
      this.prospectsArr = res.data.data
   },
   open(){
     window.location.href = '#usSgs'
   }
  },
  mounted() {
    if (this.$route.query.sgs){
      this.open()
    }
  }
}
</script>

<style lang="less" scoped>
@import '../fn.less';
.el-carousel__item:nth-child(2n+1){
    background-color: '#fff'!important;
  }
 .move{
    display: none
  }
.about-us-wrapper {
  @import "../../style/common.less";
  .full-with-banner{
    width: 100%;
    height: 46vw;
    position: relative;
    .homebanner{
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      .el-carousel .el-carousel__indicators{
        flex-direction: row;
      }
    }
  }
  .part3{
        box-sizing: border-box;
        .vw(padding,100,110,0);
         margin-top: 2vw;
        .part3_body{
            padding:5.00625vw 8.229166vw;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-wrap: nowrap;
            .box{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .item1,
        .item2{
            width: 22.395833vw;
            padding: 2.03125vw; box-sizing: border-box;
            background:#F5F5F6;
            border-radius: 1.145833vw;
            margin-bottom:1.5625vw;
            color:#6C7388; font-size: 1.04166vw;
            line-height: 1.6666vw;
            transition: all .5s linear;
            &:hover{
                background:url('../../assets/solution/part3_bg_hover.png') no-repeat center center;
                background-size: 100% 100%;
                color:#fff;
            }
        }
        .item2{
            width: 34.375vw;
        }
        .title1{
            font-size: 1.5625vw; font-weight: bold;
            padding-bottom: 1.354166vw;
        }
    }

  .career-wrapper {
    margin-top: 9.375vw;
    position: relative;
    width: 100%;
    .career-background {
      position: absolute;
      top: 50%;
      width:  100%;
      transform: translateY(-50%);
      z-index: -1;
      img {
        width: 100%;
      }
    }
    .events-wrapper {
      margin-top: 3.541666vw;
      .timeline {
        margin: 1.171875vw 0;
        height: 0.1041666vw;
        background: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray 20%, lightgray 80%, rgba(0, 0, 0, 0));
      }
      .event-row {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        position: relative;
        z-index: 2000;
        .event-wrapper {
          background-color: rgba(212, 228, 237, 0.68);
          border-radius: 0.78125vw 0.78125vw 0.78125vw 0;
          position: relative;
          width: 10.4166vw;
          padding: 0.94166vw;
          min-height: 12.04166vw;
          transition: .25s;
          cursor: pointer;
          margin: 0 1.0791666vw;

          &::before, &::after {
            content: '';
            position: absolute;
            left: 0;
          }
          &::before {
            border-radius: 0 0 0.78125vw 0.78125vw;
            bottom: -1.5625vw;
            height: 1.5625vw;
            width: 0.78125vw;
            background-color: inherit;
          }
          &::after {
            bottom: -1.5625vw;
            width: 0.78125vw;
            height: 0.78125vw;
            background: #00538D;
            border-radius: 50%;
          }

          .name {
            font-size: 0.9854166vw;
            font-family: PuHuiTi;
            font-weight: bold;
            color: #00538D;
          }
          .desc {
            padding-top: 0.520833vw;
            font-size: 0.8375vw;
            font-family:PuHuiTi;
            font-weight: 400;
            color: #6C7388;
            line-height: 1.5625vw;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 7;
          }

          &:hover {
            transform: scale(1.1) translateY(-2.5px);
            transform-origin: bottom center;
            background-color: #00538D;
            position: relative;
            z-index: 1000;
            .name, .desc {
              color: white;
            }
          }
        }
        &.leg-on-top {
          .event-wrapper {
            border-radius: 0 0.78125vw 0.78125vw 0.78125vw;
            &::before {
              border-radius: 0.78125vw 0.78125vw 0 0;
              bottom: unset;
              top: -1.5625vw;
            }
            &::after {
              bottom: unset;
              top: -1.5625vw;
            }
            &:hover {
              transform: scale(1.2) translateY(0.13020833vw);
              transform-origin: top center;
            }
          }
        }
      }
    }
  }

  .prizes-wrapper {
    margin-top: 5vw;
    .prize-row {
      position: relative;
      padding: 10.625vw 0 2.1666vw;
      display: flex;
      justify-content: center;
      width: 100%;
      .row-number {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 10.145833vw;
        font-family:PuHuiTi;
        font-weight: 800;
        color: #84C5F3;
        opacity: 0.16;
        z-index: -1;
      }
      .row-name {
        font-size: 1.2625vw;
        font-family:PuHuiTi;
        font-weight: bold;
        color: #00538D;
        position: absolute;
        right: 8.25vw;
        top: 3.25vw;
        &::after {
          content: '';
          position: absolute;
          left: -0.3125vw;
          bottom: -50%;
          width: 120%;
          height: 80%;
          transform: translateY(-20%);
          z-index: -1;
          background: linear-gradient(to right, #85bdec, rgba(0, 0, 0, 0));
        }
      }
      .row-bg {
        position: absolute;
        right: 0;
        top: 8.458333vw;
        z-index: -1;
        width: 45.0625vw;
        height: 24.208333vw;
        background: #84C5F3;
        opacity: 0.2;
      }
      .prize-wrapper {
        .prize-swiper-wrapper {
          height: 100%;
          .prize-image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            &.swiper-slide-active {
              img {
                transform: scale(1.5);
                z-index: 1;
              }
            }
            img {
              width: 100%;
              box-shadow: 0.2604166vw 0.2604166vw 0.2604166vw 0 rgba(0, 0, 0, .1);
              transition: .25s;
              &:hover {
                box-shadow: 0.2604166vw 0.2604166vw 0.5208333vw 0 rgba(0, 0, 0, .3);
              }
            }
          }
        }
      }

      &.row-1 {
        .prize-wrapper {
          min-height: 45vw;
          width: 63.4895833vw;
        }
      }

      &.row-2 {
        margin-top: 10.9370833vw;
        padding-top: 6.25vw;
        .row-number {
          left: unset;
          right: 0;
        }
        .row-name {
          top: 0;
          right: unset;
          left: 6.25vw;
        }
        .row-bg {
          width: 50.8333vw;
          height: 30.458333vw;
          background: #84C5F3;
          opacity: 0.2;
          right: unset;
          left: 50%;
          top: 10vw;
          transform: translateX(-50%);
        }
        .prize-wrapper {
          min-height: 40vw;
          width: 63.8020833vw;
        }
      }

      &.row-3 {
        margin-top: 2.41666vw;
        margin-bottom: 8.41666vw;
        .row-bg {
          width: 65.322916vw;
          height: 14.7395833vw;
          background: #84C5F3;
          opacity: 0.2;
          left: 6.7708333vw;
          right: unset;
          top: 15.041666vw;
        }
        .prize-wrapper {
          width: 60.1875vw;
          min-height: 40vw;
        }
      }
    }
  }
}

.swiper-container{
  padding-top:2vw;
  padding-bottom: 5vw;
}
@media screen and (max-width: 980px) {
  .swiper-container{
  padding-left:4vw;
  padding-right: 3vw;
  padding-top:8vw;
  padding-bottom: 4vw;
  box-sizing: border-box;
}
  .events-box{
    display: none;
  }
  .move{
    display: block
  }
  .move1{
    display: flex;
    justify-content: center;
  }
  .full-with-banner{
    .homebanner{
      margin-top: 5vw;
    }
  }
.about-us-wrapper {
  .restrict-content{
    margin-top: 10vw!important;
  }
  .part3{
        box-sizing: border-box;
        .vw(padding,180,60,0);
        .part3_body{
            padding:5.00625vw 3.229166vw;
            width: 100%;
            box-sizing: border-box;
            .box{
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
        .item1,
        .item2{
            width: 42.395833vw;
            padding: 2.03125vw; box-sizing: border-box;
            background:#F5F5F6;
            border-radius: 1.145833vw;
            margin-bottom:1.5625vw;
            color:#6C7388;
            .vvw(font-size,24);
            .vvw(line-height,40);
            transition: all .5s linear;
            &:hover{
                background:url('../../assets/solution/part3_bg_hover.png') no-repeat center center;
                background-size: 100% 100%;
                color:#fff;
            }

        }
        .item2{
            width: 34.375vw;
        }
        .title1{
            .vvw(font-size,32);
            font-weight: bold;
            padding-bottom: 1.354166vw;
        }
    }
    .career-wrapper {
    margin-top: 9.375vw;
    position: relative;
    width: 100%;
    .career-background {
      position: absolute;
      top: 50%;
      width:  100%;
      transform: translateY(-50%);
      z-index: -1;
      img {
        width: 100%;
      }
    }
    .events-wrapper {
      display: flex;
      justify-content: center;
      margin-top: 3.541666vw;
      .timeline {
        margin: 0 0;
        width: 1vw;
        height: 152vw;
        position: absolute;
        z-index: -100;
        top: 20vw;
        left: 50%;
        margin-left: -0.5vw;
        background: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray 20%, lightgray 80%, rgba(0, 0, 0, 0));
      }
      .event-row {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 100;
        .event-wrapper {
          background-color: rgba(212, 228, 237, 0.68);
          border-radius: 0.78125vw 0.78125vw 0 0.78125vw;
          position: relative;
          width: 40.4166vw;
          padding: 1.44166vw;
          min-height: 22.04166vw;
          transition: .25s;
          cursor: pointer;
          margin: 2vw 2.5791666vw;
          &::before, &::after {
            content: '';
            position: absolute;
            left: 99%;
          }
          &::before {
            border-radius: 0 0.78125vw 0.78125vw 0;
            bottom: 0;
            height: 1.28125vw;
            width: 3.2625vw;
            background-color: inherit;
          }
          &::after {
            bottom: 0;
            width: 1.28125vw;
            height: 1.28125vw;
            left: 105%;
            background: #00538D;
            border-radius: 50%;
          }

          .name {
            .vvw(font-size,24);
            font-family: PuHuiTi;
            font-weight: bold;
            color: #00538D;
          }
          .desc {
            padding-top: 0.520833vw;
            .vvw(font-size,24);
            font-family:PuHuiTi;
            font-weight: 400;
            color: #6C7388;
            line-height: 4.5625vw;
          }

          &:hover {
            transform: scale(1.02) translateY(-2.5px);
            transform-origin: bottom center;
            background-color: #00538D;
            .name, .desc {
              color: white;
            }
          }
        }
        &.leg-on-top {
          .event-wrapper {
            border-radius: 0 0.78125vw 0.78125vw 0.78125vw;
            &::before, &::after {
            content: '';
            position: absolute;
            right: 0;
            left: 0;
            top: 0;
           }
            &::before {
              border-radius: 0.78125vw  0 0 0.78125vw;
              bottom: unset;
              left:-3vw;
              top: -0.0625vw;
            }
            &::after {
              bottom: unset;
              left: -3.7vw;
              top: -0.0625vw;
            }
            &:hover {
              transform: scale(1.02) translateY(0.13020833vw);
              transform-origin: top center;
            }
          }
        }
      }
    }
    }
    .prizes-wrapper {
    margin-top: 2.4375vw;
    .prize-row {
      margin-top: 1.04166vw;
      position: relative;
      padding: 10.625vw 0 2.1666vw;
      display: flex;
      justify-content: center;
      width: 100%;
      .row-number {
        position: absolute;
        left: 0;
        top: 0;
        .vvw(font-size,40);
        font-family:PuHuiTi;
        font-weight: 800;
        color: #84C5F3;
        opacity: 0.16;
        z-index: -1;
      }
      .row-name {
        .vvw(font-size,36);
        font-family:PuHuiTi;
        font-weight: bold;
        color: #00538D;
        position: absolute;
        right: 8.25vw;
        top: 3.25vw;
        &::after {
          content: '';
          position: absolute;
          left: -0.3125vw;
          bottom: -50%;
          width: 120%;
          height: 80%;
          transform: translateY(-20%);
          z-index: -1;
          background: linear-gradient(to right, #85bdec, rgba(0, 0, 0, 0));
        }
      }
      .row-bg {
        position: absolute;
        right: 0;
        top: 8.458333vw;
        z-index: -1;
        width: 45.0625vw;
        height: 30.208333vw;
        background: #84C5F3;
        opacity: 0.2;
      }
      .prize-wrapper {
        .prize-swiper-wrapper {
          height: 100%;
          .prize-image {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            &.swiper-slide-active {
              img {
                transform: scale(4);
                z-index: 1;
              }
            }
            img {
              width: 100%;
              box-shadow: 0.2604166vw 0.2604166vw 0.2604166vw 0 rgba(0, 0, 0, .1);
              transition: .25s;
              &:hover {
                box-shadow: 0.2604166vw 0.2604166vw 0.5208333vw 0 rgba(0, 0, 0, .3);
              }
            }
          }
        }
      }

      &.row-1 {
        .prize-wrapper {
          margin-top: 2vw;
          min-height: 90vw;
          width: 67.4895833vw;
        }
      }

      &.row-2 {
        margin-top: 16.9370833vw;
        padding-top: 6.25vw;
        .row-number {
          left: unset;
          right: 0;
        }
        .row-name {
          top: -5vw;
          right: unset;
          left: 6.25vw;
        }
        .row-bg {
          width: 40.8333vw;
          height: 21.458333vw;
          background: #84C5F3;
          opacity: 0.2;
          right: unset;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
        }
        .prize-wrapper {
          height: 50vw;
          width: 63.8020833vw;
        }
      }

      &.row-3 {
        margin-top: 10.41666vw;
        margin-bottom: 10.41666vw;
        .row-bg {
          width: 65.322916vw;
          height: 14.7395833vw;
          background: #84C5F3;
          opacity: 0.2;
          left: 6.7708333vw;
          right: unset;
          top: 24.041666vw;
        }
        .prize-wrapper {
          margin-top: 2vw;
          width: 60.1875vw;
          min-height: 80vw;
        }
      }
    }
  }
  }}
</style>
<style lang="less">
.full-with-banner{
    .homebanner{
      .el-carousel .el-carousel__indicators{
        flex-direction: row;
      }
    }
  }
</style>
